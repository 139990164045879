@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: -apple-system, BlinkMacSystemFont, "Tajawal", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}
